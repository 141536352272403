const colors = {
    primary: {
      900: "#0b0b4c",
      800: "#0f0f66",
      700: "#171799",
      600: "#1e1ecc",
      500: "#2626FF",
      400: "#5151ff",
      300: "#7d7dff",
      200: "#a8a8ff",
      100: "#d4d4ff"
    },
    secondary: {
      900: "#775200",
      800: "#a98400",
      700: "#dcab00",
      500: "#ffca10",
      300: "#ffd543",
      100: "#ffe176"
    },
    grey: {
      900: "#111111",
      700: "#333333",
      500: "#555555",
      300: "#777777",
      100: "#999999"
    },
    offWhite: "#f3f3f3",
    white: "#ffffff",
    black: "#000000"
};

export default colors;