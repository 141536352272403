const languages = { // For Editor
  CF: {
    "GNU GCC C11 5.1.0": "c_cpp",
    "Clang++20 Diagnostics": "c_cpp",
    "Clang++17 Diagnostics": "c_cpp",
    "GNU G++14 6.4.0": "c_cpp",
    "GNU G++17 7.3.0": "c_cpp",
    "GNU G++20 11.2.0 (64 bit, winlibs)": "c_cpp",
    "Microsoft Visual C++ 2017": "c_cpp",
    "GNU G++17 9.2.0 (64 bit, msys 2)": "c_cpp",
    "C# 8, .NET Core 3.1": "csharp",
    "C# 10, .NET SDK 6.0": "csharp",
    "C# Mono 6.8": "csharp",
    "D DMD32 v2.091.0": "d",
    "Go 1.19": "golang",
    "Haskell GHC 8.10.1": "haskell",
    "Java 11.0.6": "java",
    "Java 17 64bit": "java",
    "Java 1.8.0_241": "java",
    "Kotlin 1.4.31": "kotlin",
    "Kotlin 1.5.31": "kotlin",
    "Kotlin 1.6.10": "kotlin",
    "OCaml 4.02.1": "ocaml",
    "Delphi 7": "pascal",
    "Free Pascal 3.0.2": "pascal",
    "PascalABC.NET 3.4.2": "pascal",
    "Perl 5.20.1": "perl",
    "PHP 8.1.7": "php",
    "Python 2.7.18": "python",
    "Python 3.8.10": "python",
    "PyPy 2.7.13 (7.3.0)": "python",
    "PyPy 3.6.9 (7.3.0)": "python",
    "PyPy 3.9.10 (7.3.9, 64bit)": "python",
    "Ruby 3.0.0": "ruby",
    "Rust 1.64.0 (2021)": "rust",
    "Scala 2.12.8": "scala",
    "JavaScript V8 4.8.0": "javascript",
    "Node.js 12.16.3": "javascript",
  },
  LC: {
    "C++": "c_cpp",
    "Java": "java",
    "Python": "python",
    "Python3": "python3",
    "C": "c_cpp",
    "C#": "csharp",
    "JavaScript": "javascript",
    "Ruby": "ruby",
    "Swift": "swift",
    "Go": "golang",
    "Scala": "scala",
    "Kotlin": "kotlin",
    "Rust": "rust",
    "PHP": "php",
    "TypeScript": "typescript",
    "Racket": "scheme",
    "Erlang": "erlang",
    "Elixir": "elixir",
    "Dart": "dart",
  },
  defaults: {
    CF: "GNU G++17 7.3.0",
    LC: "C++"
  },
};

export const languages_to_codes = { // For submit
  CF: {
    "GNU GCC C11 5.1.0": 43,
    "Clang++20 Diagnostics": 80,
    "Clang++17 Diagnostics": 52,
    "GNU G++14 6.4.0": 50,
    "GNU G++17 7.3.0": 54,
    "GNU G++20 11.2.0 (64 bit, winlibs)": 73,
    "Microsoft Visual C++ 2017": 59,
    "GNU G++17 9.2.0 (64 bit, msys 2)": 61,
    "C# 8, .NET Core 3.1": 65,
    "C# 10, .NET SDK 6.0": 79,
    "C# Mono 6.8": 9,
    "D DMD32 v2.091.0": 28,
    "Go 1.19": 32,
    "Haskell GHC 8.10.1": 12,
    "Java 11.0.6": 60,
    "Java 17 64bit": 74,
    "Java 1.8.0_241": 36,
    "Kotlin 1.4.31": 48,
    "Kotlin 1.5.31": 72,
    "Kotlin 1.6.10": 77,
    "OCaml 4.02.1": 19,
    "Delphi 7": 3,
    "Free Pascal 3.0.2": 4,
    "PascalABC.NET 3.4.2": 51,
    "Perl 5.20.1": 13,
    "PHP 8.1.7": 6,
    "Python 2.7.18": 7,
    "Python 3.8.10": 31,
    "PyPy 2.7.13 (7.3.0)": 40,
    "PyPy 3.6.9 (7.3.0)": 41,
    "PyPy 3.9.10 (7.3.9, 64bit)": 70,
    "Ruby 3.0.0": 67,
    "Rust 1.64.0 (2021)": 75,
    "Scala 2.12.8": 20,
    "JavaScript V8 4.8.0": 34,
    "Node.js 12.16.3": 55,
  },
  LC: {
    "C++": "cpp",
    "Java": "java",
    "Python": "python",
    "Python3": "python3",
    "C": "c",
    "C#": "csharp",
    "JavaScript": "javascript",
    "Ruby": "ruby",
    "Swift": "swift",
    "Go": "go",
    "Scala": "scala",
    "Kotlin": "kotlin",
    "Rust": "rust",
    "PHP": "php",
    "TypeScript": "typescript",
    "Racket": "racket",
    "Erlang": "erlang",
    "Elixir": "elixir",
    "Dart": "dart",
  },
};

export const codes_to_languages = {
  CF: {
    43: "GNU GCC C11 5.1.0",
    80: "Clang++20 Diagnostics",
    52: "Clang++17 Diagnostics",
    50: "GNU G++14 6.4.0",
    54: "GNU G++17 7.3.0",
    73: "GNU G++20 11.2.0 (64 bit, winlibs)",
    59: "Microsoft Visual C++ 2017",
    61: "GNU G++17 9.2.0 (64 bit, msys 2)",
    65: "C# 8, .NET Core 3.1",
    79: "C# 10, .NET SDK 6.0",
    9: "C# Mono 6.8",
    28: "D DMD32 v2.091.0",
    32: "Go 1.19",
    12: "Haskell GHC 8.10.1",
    60: "Java 11.0.6",
    74: "Java 17 64bit",
    36: "Java 1.8.0_241",
    48: "Kotlin 1.4.31",
    72: "Kotlin 1.5.31",
    77: "Kotlin 1.6.10",
    19: "OCaml 4.02.1",
    3: "Delphi 7",
    4: "Free Pascal 3.0.2",
    51: "PascalABC.NET 3.4.2",
    13: "Perl 5.20.1",
    6: "PHP 8.1.7",
    7: "Python 2.7.18",
    31: "Python 3.8.10",
    40: "PyPy 2.7.13 (7.3.0)",
    41: "PyPy 3.6.9 (7.3.0)",
    70: "PyPy 3.9.10 (7.3.9, 64bit)",
    67: "Ruby 3.0.0",
    75: "Rust 1.64.0 (2021)",
    20: "Scala 2.12.8",
    34: "JavaScript V8 4.8.0",
    55: "Node.js 12.16.3",
  },
  LC: {
    "cpp": "C++",
    "java": "Java",
    "python": "Python",
    "python3": "Python3",
    "c": "C",
    "csharp": "C#",
    "javascript": "JavaScript",
    "ruby": "Ruby",
    "swift": "Swift",
    "go": "Go",
    "scala": "Scala",
    "kotlin": "Kotlin",
    "rust": "Rust",
    "php": "PHP",
    "typescript": "TypeScript",
    "racket": "Racket",
    "erlang": "Erlang",
    "elixir": "Elixir",
    "dart": "Dart",
  }
};

export const codes_to_snippets = { // For leetcode indexing
  "cpp": 0,
    "java": 1,
    "python": 2,
    "python3": 3,
    "c": 4,
    "csharp": 5,
    "javascript": 6,
    "ruby": 7,
    "swift": 8,
    "go": 9,
    "scala": 10,
    "kotlin": 11,
    "rust": 12,
    "php": 13,
    "typescript": 14,
    "racket": 15,
    "erlang": 16,
    "elixir": 17,
    "dart": 18,
}

export default languages;